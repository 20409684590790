
/**
 * FPU Settings for the sections
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 * @todo [ ] Update this file for the FPU SETTINGS handler.
 */

import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Project from "@/model/Project";
import SectionState from "@/model/ModelSectionState";
import Section from "@/model/ModelSection";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import { ActionMessage } from "@/model/Messages/statusMessage";

@Component({
  name: "FPUSettings",
  validations: {
    customState: {
      filename: {
        required,
        minLength: minLength(2)
      },
      brightness: {
        required,
        minLength: minLength(1)
      }
    }
  },
  computed: {
    ...mapGetters("projects", {
      currentSectionDetails: "GetCurrentSectionDetails",
      currentProjectDetails: "GetCurrentProject",
      currentSectionState: "GetCurrentSectionDetailsStateModal"
    })
  },
  methods: {
    ...mapActions("projects", {
      SetFPUSettings: "SetFPUSettings",
      getSectionsDetails: "GetSectionDetail"
    })
  }
})
export default class FPUSettings extends Mixins(confirmModal) {
  /*----------  Vuex actions and getter  ----------*/
  readonly currentSectionDetails!: Section;
  readonly currentProjectDetails!: Project;
  readonly currentSectionState!: SectionState;

  private getSectionsDetails!: Function;
  private SetFPUSettings!: Function;

  /*----------  Props  ----------*/
  @Prop({ type: Object }) sectionInformation!: Section;
  @Prop({ type: Boolean, default: false }) updateSectionForm!: boolean;

  /*----------  Local data  ----------*/
  sectionUpdateStatus: null | ActionMessage = null;
  updateStatus: null | object = null;
  isLoading: boolean = false;
  localCleared = false;
  customState = {
    cleared: false,
    filename: "",
    brightness: 0
  };

  /*----------  Vue watch  ----------*/
  @Watch("currentSectionState", { immediate: true })
  handlerCurrentSectionState(val: SectionState) {
    if (val && val.fpuSettings) {
      this.localCleared = !val.fpuSettings.cleared;
    }
  }
  @Watch("localCleared")
  handleLocalCleared(val: boolean) {
    if (this.currentSectionState && this.currentSectionState.fpuSettings) {
      this.currentSectionState.fpuSettings.cleared = !val;
    }
  }

  /*----------  Vue life cycles  ----------*/
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent: any, modalId: string) => {
      if (modalId === "createSectionForm") {
        this.updateStatus = null;
      }
    });
  }
  created() {
    this.getSectionsDetails(this.sectionInformation.id);
  }

  /*----------  Computed property  ----------*/
  get filenameMin() {
    return !this.currentSectionState.fpuSettings?.filename.length && this.localCleared === true;
  }

  /*----------  Methods  ----------*/

  HandleUpdateFPUSettings() {
    this.sectionUpdateStatus = null;

    this.confirm(
      this.$t("projects.singleProjectView.displayState.displayConfirmModal.fpuConfirmUpdate").toString(),
      async () => {
        this.isLoading = true;
        let { filename, cbradioEnabled, prewarningEnabled } = this.currentSectionState.fpuSettings;

        let res = await this.SetFPUSettings({
          value: {
            cbRadioEnabled: cbradioEnabled,
            prewarningEnabled: prewarningEnabled,
            cleared: !this.localCleared,
            brightnessOverride: !this.localCleared ? false : this.currentSectionState.fpuSettings.brightnessOverride,
            brightness: !this.localCleared ? 0 : this.currentSectionState.fpuSettings.brightness,
            filename: !this.localCleared ? "" : filename.length ? `${filename}.bmp` : ""
          },
          id: this.currentSectionDetails.id
        });
        if (res.status === 200) {
          this.sectionUpdateStatus = {
            class: "success",
            msg: this.$t("projects.singleProjectView.displayState.displayConfirmModal.fpuUpdateSuccess").toString()
          };
          this.getSectionsDetails(this.sectionInformation.id);
          this.isLoading = false;
        } else if (res.response.status === 400) {
          this.sectionUpdateStatus = {
            class: "warning",
            msg: this.$t("archiveProjects.actions.action400res").toString()
          };
          this.isLoading = false;
          this.getSectionsDetails(this.sectionInformation.id);
        } else {
          this.isLoading = false;
          this.sectionUpdateStatus = {
            class: "danger",
            msg: res.response.data.message
          };
        }
      },
      () => {
        this.getSectionsDetails(this.sectionInformation.id);
      }
    );
  }
  handleResetSettings() {
    this.confirm(
      this.$t("projects.singleProjectView.displayState.displayConfirmModal.fpuCancelAction").toString(),
      async () => {
        this.getSectionsDetails(this.currentSectionDetails.id);
      }
    );
  }
}
