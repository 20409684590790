
/**
 * Cwu settings for the CWU types of projects
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import {Component, Prop, Watch, Mixins} from "vue-property-decorator";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import {mapGetters, mapActions} from "vuex";
import Multiselect from "vue-multiselect";
import Section from "@/model/ModelSection";
import Project from "@/model/Project";
import {ActionMessage} from "@/model/Messages/statusMessage";
import {WEP_MOCK_URL} from "@/shared/keycloak";
import UserAccess from "@/model/User/UserAccess";
import CwuSettingsFullSize from "@/components/project/SingleProject/Schematic/VwaSettingsFullSize.vue";
import CwuSettingsTMD from "@/components/project/SingleProject/Schematic/VwaSettingsTMD.vue";
import VwaPreWarningLamp from "@/components/project/SingleProject/Schematic/VwaPreWarningLamp.vue";

import {Projects} from "@/services/projectService";

@Component({
  name: "CwuSettings",
  components: {
    Multiselect,
    CwuSettingsFullSize,
    CwuSettingsTMD,
    VwaPreWarningLamp
  },
  computed: {
    ...mapGetters("projects", {
      currentSection: "GetCurrentSectionDetails",
      manualOverrideOptions: "GetManualOverrideOptionsFromState",
      currentProject: "GetCurrentProject"
    }),
    ...mapGetters(["getUserAccess"])
  },
  methods: {
    ...mapActions("projects", {
      getSectionsDetails: "GetSectionDetail",
      GetManualOverrideSignOption: "GetManualOverrideSingOptions",
      manualOverrideToggle: "CWUManualOverrideToggle"
    })
  }

})
export default class CwuSettings extends Mixins(confirmModal) {
  WEP_MOCK_URL = WEP_MOCK_URL;
  /*----------  Vuex  ----------*/
  readonly currentSection!: Section;
  private readonly manualOverrideOptions!: object;
  readonly currentProject!: Project;
  readonly getUserAccess!: UserAccess;

  private vwaLetters!: any;
  private getSectionsDetails!: Function;
  private GetManualOverrideSignOption!: Function;
  private manualOverrideToggle!: Function;

  /*----------  Props  ----------*/
  @Prop({type: Object, default: () => ({})}) sectionInformation!: Section;
  @Prop({type: String, default: "48x48"}) displaySize!: string;

  /*----------  Local data  ----------*/
  isLoading = false;
  cwuUpdateStatus: ActionMessage | null = null;

  localManualOverride: any = false;

  /*----------  Vue life cycles  ----------*/
  created() {
    this.GetManualOverrideSignOption(this.sectionInformation.id);
  }

  mounted() {
    this.getSectionsDetails(this.sectionInformation.id).then(() => {
      if (this.currentSection.state.cwuSettings) {
        this.localManualOverride = this.currentSection.state.cwuSettings.manualOverride;
      }
    });
  }

  /*----------  Watch  ----------*/
  @Watch("currentSection", {immediate: true})
  HandleCurrentSectionWatch(sec: Section) {
    if (sec && sec.state) {
      const settings = sec.state.cwuSettings;
      if (settings && typeof settings.filename === "string") {
        let isEmpty = settings.filename === "";
        let isTraffic =
          (settings.customFilenameEnabled &&
            settings.filename.replace(".bmp", "") === settings.customFilenameTraffic) ||
          settings.filename.replace(".bmp", "") === "Stau";
        let isTrafficRisk =
          (settings.customFilenameEnabled &&
            settings.filename.replace(".bmp", "") === settings.customFilenameTrafficRisk) ||
          settings.filename.replace(".bmp", "") === "Stgefahr";
        if (!settings.manualOverride) {
          //@ts-ignore
          sec.state.cwuSettings.filename = null;
        } else {
          //@ts-ignore
          sec.state.cwuSettings.filename = {
            name: isTraffic
              ? this.$t("project.section.displayInterval.filenames.Stau")
              : isTrafficRisk
                ? this.$t("project.section.displayInterval.filenames.Stgefahr")
                : isEmpty
                  ? this.$t("project.section.displayInterval.filenames.empty")
                  : settings.filename.replace(/.bmp/g, ""),
            value: settings.filename
          };
        }
      }
    }
    this.localManualOverride = this.currentSection.state.cwuSettings?.manualOverride;
  }

  /*----------  Methods   ----------*/
  async resetDisplayLocalState() {
    const sectionDetails = await this.getSectionsDetails(this.currentSection.id);
    if (sectionDetails.status === 200) {
      this.isLoading = false;
    }
  }

  // handle radio button click change to automatic mode or show multiselect of signs
  handleManualOverride() {
    this.cwuUpdateStatus = null;
    if (!this.currentSection.state.cwuSettings?.manualOverride)
      this.confirm(
        this.$t("project.section.displayInterval.confirmModals.msgToggleOnOverride").toString(),
        async () => {
          this.isLoading = true;
          const filename = this.currentSection.state.cwuSettings?.filename;
          let MOSettings = {
            id: this.currentSection.state.cwuSettings?.id,
            manualOverride: this.currentSection.state.cwuSettings?.manualOverride,
            // @ts-ignore
            filename: filename ? filename.value : ""
          };
          const res = await this.manualOverrideToggle({
            sectionId: this.sectionInformation.id,
            MOSettings
          });
          if (res.status === 200) {
            this.resetDisplayLocalState();
            this.cwuUpdateStatus = {
              class: "success",
              msg: this.currentSection.state.cwuSettings?.manualOverride
                ? this.$t("project.section.displayInterval.confirmModals.msgManualSuccessMsg")
                : this.$t("project.section.displayInterval.confirmModals.msgAutoSuccessMsg")
            };
          } else if (res.response.status === 400) {
            this.cwuUpdateStatus = {
              class: "warning",
              msg: (this as any).$t("archiveProjects.actions.action400res")
            };
            this.isLoading = false;
            this.getSectionsDetails(this.sectionInformation.id);
          } else {
            this.isLoading = false;
            this.cwuUpdateStatus = {
              class: "danger",
              msg: res.response.data.message
            };
          }
        },
        () => {
          this.isLoading = false;
          this.resetDisplayLocalState();
          return;
        }
      );
    else this.localManualOverride = true;
  }

  HandleAddCustomImage(customFilename: string) {
    const filename = customFilename.replace(/.bmp/g, "").replace(/.gif/g, "");
    this.cwuUpdateStatus = null;
    this.closeSignMultiSelect();
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.msgSetSign", {
        sign: filename
      }).toString(),
      async () => {
        this.isLoading = true;
        const manualOverrideSettings = {
          id: this.currentSection.state.cwuSettings?.id,
          manualOverride: true,
          filename: `${filename}.bmp`
        };

        const updateSignCWU = await this.manualOverrideToggle({
          MOSettings: manualOverrideSettings,
          sectionId: this.currentSection.id
        });

        if (updateSignCWU.status === 200) {
          this.cwuUpdateStatus = {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.msgSetSignSuccess", {
              sign: filename
            })
          };
          this.resetDisplayLocalState();
        } else {
          this.resetDisplayLocalState();
          this.isLoading = false;
          this.cwuUpdateStatus = {
            class: "danger",
            msg: updateSignCWU
          };
        }
      },
      async () => {
        await this.resetDisplayLocalState();
      }
    );
  }

  handleSignChange() {
    this.cwuUpdateStatus = null;
    this.closeSignMultiSelect();
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.msgSetSign", {
        sign: (this as any).currentSection.state.cwuSettings?.filename.name
      }).toString(),
      async () => {
        this.isLoading = true;
        const filename = this.currentSection.state.cwuSettings?.filename;
        const manualOverrideSettings = {
          id: this.currentSection.state.cwuSettings?.id,
          manualOverride: true,
          // @ts-ignore
          filename: filename ? filename.value : ""
        };

        const updateSignCWU = await this.manualOverrideToggle({
          MOSettings: manualOverrideSettings,
          sectionId: this.currentSection.id
        });

        if (updateSignCWU.status === 200) {
          this.cwuUpdateStatus = {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.msgSetSignSuccess", {
              sign: (this as any).currentSection.state.cwuSettings?.filename.name
            })
          };
          this.resetDisplayLocalState();
        } else {
          this.resetDisplayLocalState();
          this.isLoading = false;
          this.cwuUpdateStatus = {
            class: "danger",
            msg: updateSignCWU
          };
        }
      },
      async () => {
        await this.resetDisplayLocalState();
      }
    );
  }

  // handle div events of changing modes
  async handleManualToggle(manual: boolean) {
    if (this.currentProject.isArchived) return;
    this.cwuUpdateStatus = null;
    if (!manual) {
      this.confirm(
        this.$t("project.section.displayInterval.confirmModals.msgToggleOnOverride", {
          overrideStatus: manual
        }).toString(),

        async () => {
          this.isLoading = true;
          const filename = this.currentSection.state.cwuSettings?.filename;
          let MOSettings = {
            id: this.currentSection.state.cwuSettings?.id,
            manualOverride: manual,
            // @ts-ignore
            filename: filename ? filename.value : ""
          };

          const toggleCwuManualOverride = await this.manualOverrideToggle({
            sectionId: this.sectionInformation.id,
            MOSettings
          });

          if (toggleCwuManualOverride.status === 200) {
            this.resetDisplayLocalState();
            this.cwuUpdateStatus = {
              class: "success",
              msg: manual
                ? this.$t("project.section.displayInterval.confirmModals.msgManualSuccessMsg")
                : this.$t("project.section.displayInterval.confirmModals.msgAutoSuccessMsg")
            };
          } else {
            this.isLoading = false;
            this.cwuUpdateStatus = {
              class: "danger",
              msg: toggleCwuManualOverride
            };
          }
        },
        () => {
          this.resetDisplayLocalState();
          return;
        }
      );
    } else this.localManualOverride = true;
  }

  closeSignMultiSelect() {
    // close multiselect after select a sign
    let element: HTMLElement | any = document.getElementById("selectedSign") as HTMLElement | any;
    element.focus();
    element.blur();
  }
}
